import styled from 'styled-components'

const ItemDetailContainer = styled.div`
  min-height: 274px;
  position: relative;
  background-color: #191d2c;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  padding: 40px;

  &:before {
    content: '';
    position: absolute;
    min-height: 272px;
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  &.HEAD {
    .attribute-content {
      .avatar-box {
        display: flex;
        justify-content: flex-start;
        .avatar {
          width: 142px;
          height: 142px;
        }
      }
    }
  }

  .attribute-content {
    .avatar-box {
      display: flex;
      justify-content: flex-start;
      .avatar {
        width: 142px;
        height: 142px;
        border: 1px solid #044817;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        flex: 1;
        background: linear-gradient(90deg, #044817 0%, #0f121c 100%);
        padding-top: 20px;
        padding-left: 10px;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;

        .info__title {
          font-size: 24px;
        }

        .info__type__left {
          color: #ccbb66;
          display: inline-block;
          width: 70px;
        }

        .info__type__icon {
          display: inline-block;
          width: 35px;
        }
      }
    }

    .content-box {
      margin: 20px 0;

      .attribute-head {
        position: relative;
        background-color: #0f121c;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );
        -webkit-clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );

        &:before {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          background-color: #0f121c;
          display: block;
          clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
          -webkit-clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
        }

        p {
          position: relative;
          color: #fff;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .attribute-info {
        /* padding: 20px 10px;
        height: 98px;
        overflow: auto;
        display: block;
        white-space-collapse: preserve-breaks; */

        padding: 10px;
        color: rgba(255,255,255,0.48);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        white-space-collapse: preserve-breaks;

        p {
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-weight: 400;
          line-height: 35px;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: space-evenly;
  }

  .attribute__item {
    display: inline-block;
    min-width: 83px;
  }
`

export default ItemDetailContainer
