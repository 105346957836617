import styled from 'styled-components'

const HorseModalAvailableStyled = styled.div`
  position: relative;
  .horse-detail-container {
    width: 100%;
    height: 100%;
    min-height: 500px;
    gap: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      row-gap: 40px;
    }
    .quick-view-left {
      width: 400px;
      display: flex;
      align-items: center;
      padding: 10px;
      .background {
        width: 300px;
      }
    }

    .quick-view-center {
      width: auto;
      padding: 16px 10px;
      &.only-view{
        &.setting-lendding{
          .ability-box {
            padding: 5px 5px 5px 20px !important;
            .title {
              font-size: 16px;
            }
            .level {
              margin-right: 5px;
            }
          }
          button{
            pointer-events: none !important;
            cursor: not-allowed !important;
            opacity: 0.5 !important;
          }
        }
        button.push-doping{
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.5;
        }

        .plus-item{
          &.available{
            button.push-doping{
              pointer-events: all;
              cursor: pointer;
              opacity: 1;
            }
          }
        }
        .ability-box-container{
          &.availaible{
            .push-doping {
              pointer-events: all;
              cursor: pointer;
              opacity: 1;
              &.disable{
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
            }
          }
        } 
      }
    }

    .quick-view-right {
      width: 302px;
      padding-top: 10px;
      .right-bottom {
        position: absolute;
        bottom: -60px;
        right: 0px;
      }
    }
  }
  .btn-bottom {
    gap: 20px;
    display: flex;
    padding-top: 50px;
    justify-content: space-around;
  }

  
`
export const ConfirmBorrowStyle = styled.div`
  .vip_expired {
    margin-top: 20px;
    font-size: 20px;
    display: flex;

    .vip-icon {
      margin-left: -10px;
      margin-right: 15px;
      width: 32px;
      height: 32px;
    }

  }
  .text__vip {
    color: #fff566;
  }
  .text_align_center {
    text-align: center;
  }
`;

export const ChooseContractLendingContainer = styled.div`
  margin-top: 20px;
  .ant-radio-wrapper {
    span {
      color: #fff;
      font-size: 18px;
    }
  }

  input {
    width: 270px;
  }

  .error {
    color: red;

    margin-bottom: 20px;
  }
`;

export default HorseModalAvailableStyled
