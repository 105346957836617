import styled from 'styled-components'

interface HorseStyledProps {
  isActive: boolean
}

const MyHorseItemStyled = styled.tr<HorseStyledProps>`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0 calc(100% - 14px));
  vertical-align: middle;
  background-color: ${({ isActive }) => (isActive ? '#252b43' : '#3a3a3aa3')} !important;
  height: 52px;

  cursor: pointer;
  td {
    font-size: 16px;
    line-height: 20px;

    .horse-avatar {
      width: 50px;
    }

    .horse-class {
      top: 2px;
    }
  }
  .horse-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .row-name {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .used-doping{
    width: 40px;
  }

  .width-25 {
    width: 25%;
    text-align: left;
  }

  .width-20 {
    width: 20%;
    text-align: left;
  }

  .width-15-name {
    width: 15%;
    text-align: left;
  }

  .width-10 {
    width: 10%;
  }

  .width-5 {
    width: 5%;
  }

  .width-4 {
    width: 4%;
  }

  .width-8 {
    width: 8%;
  }

  .width-12 {
    width: 12%;
  }

  .width-13 {
    width: 13%;
  }

  .width-15 {
    width: 15;
  }

  .width-17 {
    width: 17%;
  }

  .width-18 {
    width: 18%;
  }

  .width-19 {
    width: 19%;
  }

  .width-21 {
    width: 21%;
  }

  .width-22 {
    width: 22%;
  }

  .width-27 {
    width: 27%;
  }

  .padding-left-name {
    padding-left: 7.5%;
  }

  .padding-left {
    padding-left: 7.5%;
  }

  .padding-left-5 {
    padding-left: 5%;
  }

  .padding-left-4 {
    padding-left: 4%;
  }

  .padding-left-59 {
    padding-left: 5.8%;
  }

  .padding-left-6 {
    padding-left: 6.4%;
  }

  .stt {
    width: 50px;
  }

  .gender__center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .gender {
      width: 35px;
    }

    .region {
      width: 30px;
      height: 30px;
    }
  }

  .profile__image__armor {
    display: flex;
    justify-content: center;
  }

  .image_bloodline {
    height: 50px;
    min-width: 60px;
  }
`

export default MyHorseItemStyled
