import styled from 'styled-components'

const HorseDetail = styled.div`
  /* position: relative; */
  background: #121520;
  padding: 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }
  .horse-detail-container {
    padding-top: 30px;
    width: 100%;
    height: 100%;
    min-height: 500px;
    gap: 12px;
    display: grid;
    grid-template-columns: auto auto auto;
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      row-gap: 40px;
    }
    .quick-view-left {
      /* background: rgba(4, 9, 24, 0.7); */
      width: 300px;
      display: flex;
      align-items: center;
      /* padding: 10px; */
    }

    .quick-view-center {
      width: auto;
      padding: 16px 10px;
    }

    .quick-view-right {
      width: 300px;
      padding-top: 10px;
      .right-bottom {
        position: absolute;
        bottom: 20px;
        right: 10px;
      }
    }
  }
  .btn-bottom {
    gap: 20px;
    display: flex;
    padding-top: 50px;
    justify-content: space-around;
  }
`
export default HorseDetail

export const ConfirmBorrowStyle = styled.div`
  .vip_expired {
    margin-top: 15px;
    font-size: 20px;
    display: flex;

    .vip-icon {
      margin-left: -10px;
      margin-right: 15px;
      width: 32px;
      height: 32px;
    }
  }
  .text__vip {
    color: #fff566;
  }
  .text_align_center {
    text-align: center;
  }
`;
