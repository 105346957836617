/* eslint-disable @typescript-eslint/no-explicit-any */
import { TRANSPARENT } from 'assets/images'
import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  img[src=""], img:not([src]){
    opacity: 0;
  }

  img[src="*"]{
    opacity: 1;
  }
`

const MemoizedLoadImageCommon = ({ srcImg, id = '', className = '', alt = '' }: any) => {
  const addDefaultSrc = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = TRANSPARENT;
  }

  return (
    <ContentStyled>
      <img src={srcImg} onError={addDefaultSrc} id={id} className={className} alt={alt} loading="lazy" />
    </ContentStyled>
  )
}
export default React.memo(MemoizedLoadImageCommon) 
