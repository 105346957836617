import styled from "styled-components"

const GuildDetailContainer = styled.div`
  width: 100%;
  
  @media (max-width: 1400px) {
    .contain_info_borse {
      overflow-x: scroll;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #121520;
      }
      ::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: rgba(52, 58, 64, 0.2);
      }
      ::-webkit-scrollbar-thumb:hover{
        background: rgba(52, 58, 64, 0.4);
      }
      ::-webkit-scrollbar-thumb:active{
        background: rgba(52, 58, 64, .9);
      }
    }
  }
`

export default GuildDetailContainer