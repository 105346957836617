/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import MemoizedImageHorsePopupClose from 'shared/MemoizedImage/HorsePopupClose'
import { Modal } from 'shared'
import { LINE_TEXT } from 'assets/images'
import { HorseBloodlineStyle } from './styled'
import { Spin } from 'antd'

interface MProps {
  onClose: () => void
  title?: string
  content?: string
  width?: any
  height?: any
}

const ModalHorseBloodline = ({ onClose, title = '', content = '', width = 250, height = 130 }: MProps) => {
  const [loadingModal, setLoadingModal] = useState(true)

  useEffect(() => {
    setLoadingModal(() => false)
  }, [title, content])
  
  return (
    <Modal onOverlayClick={onClose}>
      <HorseBloodlineStyle width={width} height={height}>
        <button disabled={loadingModal} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <MemoizedImageHorsePopupClose />
        </button>
        {loadingModal ? (
          <div className='loading__item'>
            <Spin />
          </div>
        ) : (
          <div className='content-box'>
            <div className='information'>
              <div className='information-box'>
                <div
                  className='attribute active'
                >
                  {title}
                  <img src={LINE_TEXT} alt='' className='line-text' />
                </div>
              </div>
              <div className='content mb-2'>
                  <p>{ content }</p>
              </div>
            </div>
          </div>
        )}
      </HorseBloodlineStyle>
    </Modal>
  )
}

export default  ModalHorseBloodline
