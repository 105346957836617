import styled from 'styled-components'

const ChooseHorseModalStyled = styled.div`
  .choose-horse-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 32px;
    position: relative;
    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .race-fee {
      margin: 16px 0 8px 0;
      max-width: 300px;
      span {
        margin-left: 5px;

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: -1px;
        }
      }
    }

    .race-name-container {
      margin-bottom: 16px;

      .race-name {
        font-size: 24px;
        line-height: 20px;
      }
      .gate-no {
        background-color: #267e5a;
        border-radius: 4px;
        font-size: 18px;
        padding: 2px 10px;
        &.no-1{
          background: #ffffff;
          color: #000000;
        }
        &.no-2{
          background: #000000;
          color: #ffffff;
        }
        &.no-3{
          background: red;
          color: #ffffff;
        }
        &.no-4{
          background: #3366FF;
          color: #ffffff;
        }
        &.no-5{
          background: yellow;
          color: #000000;
        }
        &.no-6{
          background: yellow;
          color: #000000;
        }
        &.no-7{
          background: green;
          color: #ffffff;
        }
        &.no-8{
          background: green;
          color: #ffffff;
        }
        &.no-9{
          background: #FF8C00;
          color: #ffffff;
        }
        &.no-10{
          background: #FF8C00;
          color: #ffffff;
        }
        &.no-11{
          background: #FF33CC;
          color: #ffffff;
        }
        &.no-12{
          background: #FF33CC;
          color: #ffffff;
        }
      }
    }

    .race-info-container {
      gap: 32px;
      padding-bottom: 25px;
      border-bottom: 2px solid ${({ theme }) => theme.color.black};

      .race-info-item {
        gap: 8px;

        .race-info-title {
          font-size: 14px;
          line-height: 14px;
        }

        .race-info-content {
          font-size: 16px;
          line-height: 19px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-top: -3px;
          margin-left: 2px;
        }
      }
    }

    .search-horse-container {
      margin: 21px 0 16px 0;
      .no-horse {
        color: #a3a5ab;
      }

      .search-title {
        font-size: 16px;
        line-height: 24px;
      }

      .search-input {
        background-color: ${({ theme }) => theme.color.black};
        opacity: 0.85;
        width: 240px;
      }
    }

    .horse-list-container {
      max-height: 450px;
      .active {
        background: ${({ theme }) => theme.color.primary};
      }
    }

    .horse-list-container::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 2px 0;
      background-color: #000;
    }

    .horse-list-container::-webkit-scrollbar {
      width: 5px;
    }

    .horse-list-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2f323e;
      border: 1px solid #000;
    }

    .confirm-horse {
      margin-top: 32px;
      &-btns {
        margin-top: 26px;
        button {
          border: 0;
          background: none;
          padding: 0;
          margin: 0 40px;
        }
      }
      &-title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
    .race-type {
      padding: 0 0 6px 20px;
    }

    .box_tokyo {
      font-weight: 600;
      background-color: #ff1f6a;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .box_seoul {
      font-weight: 600;
      background-color: #1ecfcf;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .box_galaxy_road {
      font-weight: 600;
      background-color: #e6a01f;
      width: 120px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }
    .title {
      min-width: 80px;
    }
  }
  .box__bloodline {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .image_bloodline {
      display: flex;
      align-items: center;
      height: 70px;
      margin-right: 15px;
    }

    .image_run_type {
      display: flex;
      align-items: center;
      height: 35px;
      margin-right: 15px;
    }

    .image_charitic {
      display: flex;
      align-items: center;
      height: 60px;
      margin-right: 15px;
    }
  }
`

export default ChooseHorseModalStyled
