import styled from 'styled-components'

const AbilityBoxStyled = styled.div`
  .ability-box-container {
    clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
    background: ${({ theme }) => theme.color.gradientDarkGreen};
    padding: 1px;

    .ability-box {
      clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
      background: ${({ theme }) => theme.color.neutral};
      padding: 10px 10px 10px 20px;

      .title {
        font-size: 20px;
        line-height: 19px;
      }

      .level {
      }
    }
    .push-doping{
      background: transparent;
      border: none;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
      position: relative;
      margin-bottom: -10px;
      &.use {
        margin-right: 15px;
      }
      .shadown {
        height: 40px;
        margin-bottom: 10px;
      }
      .d-block {
        height: 25px;
        position: absolute;
        top: 15%;
        left: 50%;
        -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        transform: translate(-50%,0);
      }
      img{
        height: 40px;
        margin-bottom: 10px;
      }
      span {
        color: #fff;
        position: absolute;
        top: -10px;
        background: #FF7A00;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 16px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        right: -13px;
        padding: 8px;
      }
    }
  }

  .bottom-frame {
    bottom: -10px;
    right: 0;
    width: 100%;
    height: 18px;
  }

  .img__ability {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`

export default AbilityBoxStyled
