/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { AbilityBoxItem } from 'features/Horse/components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'hooks'

import Stats from 'features/components/Stats'
import {
  Horse,
  HorseAbility,
  ListBoostItemsEquipped,
  ListBootsType
} from 'models'
import { sortAbilities } from 'utils/helper'
import HorseCareerWinRates from '../HorseCareerWinRates'
import HorseBodyInfoStyled from './styled'
import openNotification from 'utils/toast'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { handleAsyncRequest } from 'utils/helper'
import RemoveItemModal from 'features/Horse/components/RemoveItemModal'
import SelectItemModal from 'features/Horse/components/SelectItemModal'
import horseApi from 'apis/horseApi'
import {
  SHADOW_DOPING,
  UNION,
  ICON_DOPING
} from 'assets/images'
import { getCharacteristic, getColorHorse, getImageBloodline, getRunType } from 'utils/getLinkImage'
import { STRING_BLOODLINE_CHARACTER } from 'apps/constants'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'

interface HorseBodyInfoLendingProps {
  horse: Horse
  isLending?: boolean
  isShowSettingItemDoping?: boolean
  customClass?: string
  fetchHorse: () => void
}

function HorseBodyInfoLending({ 
  horse, 
  isLending, 
  isShowSettingItemDoping, 
  customClass,
  fetchHorse 
}: HorseBodyInfoLendingProps) {
  const { t } = useTranslation()
  const { horse_id } = useParams<string>()
  const [currentStatsSelected, setCurrentStatsSelected] = useState<string>('')
  const [bootsTypeListDetail, setBootsTypeDetail] = useState<any>()
  const [isSelectItemModal, toggleIsSelectItemModal] = useToggle(false)
  const [isChangeItem, toggleIsChangeItem] = useToggle(false)
  const [listItemsEquipped, setListItemsEquipped] = useState<ListBoostItemsEquipped[]>([])
  const [currentPosition, setCurrentPosition] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [isModalRemoveItemOpen, toggleIsRemoveItemOpen] = useToggle(false)
  const [itemDetail, setItemDetail] = useState<ListBootsType[]>([])
  const [currentIdRemove, setCurrentIdRemove] = useState<string>('')
  const [currentStatsSelectedTemp, setCurrentStatsSelectedTemp] = useState<string>('')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const useItemDopingSucess = () => {
    fetchHorse()
    pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyBootsItems`), true)
    toggleIsSelectItemModal(false)
    toggleIsChangeItem(false)
    setCurrentStatsSelected('')
  }

  const getPosition = (data: any) => {
    setCurrentPosition(data)
  }

  function handleCurrentAmountStats(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.amount)
    } else {
      return (0)
    }
  }
  function handleGetPosition(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.position)
    } else {
      return (0)
    }
  }

  function handleListGetPosition(currentType: any) {
    const found = horse?.boost_items_equipped.filter((element) => element.slot == currentType);
    if (found) {
      return (found)
    } else {
      return ([])
    }
  }

  function handleGetIdItems(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.id)
    } else {
      return (0)
    }
  }

  function handleGetIdItemsUse(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.id)
    } else {
      return (0)
    }
  }

  function handleCurrentImgDoping(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.boost.img)
    } else {
      return (0)
    }
  }

  const handleRemoveItem = async () => {
    const [error, result]: any = await handleAsyncRequest(horseApi.deleteHorseBoosterItem(String(horse_id), String(currentIdRemove)))
    if (result) {
      fetchHorse()
      pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyRemovedBootsItems`), true)
      setCurrentStatsSelected('')
    }
    if (error) {
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
    toggleIsRemoveItemOpen(false)
  }

  const handleChangeItem = async () => {
    setCurrentStatsSelected(currentStatsSelectedTemp)
    toggleIsSelectItemModal(true)
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(true)
  }

  const handleCloseModal = () => {
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(false)
  }

  const fetchUserBootsItems = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      tokenId: horse_id,
      position: currentPosition,
      limit: 30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data?.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      setBootsTypeDetail([])
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }

  const showModalSelectItem = (key: string) => {
    toggleIsSelectItemModal(true)
    setCurrentStatsSelected(key)
    setCurrentPosition('')
  }

  function handleCurrentOwnerDoping(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.item_owner.id)
    } else {
      return (0)
    }
  }  

  const showModalRemoveItem = async (id: string, type: string) => {
    await handleShowItemsDetail(id)
    toggleIsRemoveItemOpen(true)
    setCurrentStatsSelectedTemp(type)
  }

  const handleShowItemsDetail = async (id: any) => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(horseApi.getBoostItemsDetail(id))
    if (result) {
      setItemDetail(result?.data)
      setCurrentIdRemove(result?.data.id)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }

  const fetchListBootsItemsChange = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      tokenId: horse_id,
      position: currentPosition,
      limit:  30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListChangeBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data?.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      setBootsTypeDetail([])
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }

  const checkInLending = () => {
    if(isShowSettingItemDoping)
      return true
    else return false
  }
  useEffect(() => {
    if (isShowSettingItemDoping) {
      setListItemsEquipped(horse?.boost_items_equipped)
    }
  }, [horse])

  useEffect(() => {
    if (isChangeItem) {
      fetchListBootsItemsChange()
    }
  }, [isChangeItem])

  useEffect(() => {
    if (currentStatsSelected !== '' && !isChangeItem) {
      fetchUserBootsItems()
    }
  }, [currentStatsSelected])
  const setToggleIsSelectItemModal = () => {
    toggleIsSelectItemModal(false)
    setCurrentStatsSelected('')
    toggleIsChangeItem(false)
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <HorseBodyInfoStyled colorHorse={getColorHorse(horse?.bloodline?.color)}>
      {horse && (
        <div className={customClass}>
          <div className={`right-body ${isLending ? 'lending-container' : ''}`}>
            <div className='right'>
              <div className='box__bloodline'>
                {/* bloodline */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.BLOODLINE,`${horse?.bloodline?.name} - ${horse?.bloodline?.type}`)}
                  className='image_bloodline'>
                  <MemoizedLoadImageCommon srcImg={getImageBloodline(horse?.bloodline?.name)} />
                </div>

                {/* run type */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, horse?.run_type)}
                  className='image_run_type'>
                  <MemoizedLoadImageCommon srcImg={getRunType(horse.run_type)} />
                </div>

                {/* characteristic */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, horse?.characteristic_display)}
                  className='image_charitic'>
                  <MemoizedLoadImageCommon srcImg={getCharacteristic(horse.characteristic_display)} />
                </div>

                {/* color */}
                <div
                  onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.COLOR, horse?.bloodline?.color)}
                  className='image_color'></div>
              </div>
              <div className='career-win-rates'>
                <HorseCareerWinRates horse={horse} isLending={isLending} />
              </div>
              <div className='stats-body-container'>
                <Stats horse={horse} isLending={isLending} customClass='lending-stats' />
              </div>
              <div className='ability-container row gy-4'>
                {horse && sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                  <div className='col-12 col-xxl-6 col-sm-12' key={index}>
                    <AbilityBoxItem
                      name={skill.name_en}
                      level={skill.level} customClass='custom-bottom-frame'
                      currentStats={() => setCurrentStatsSelected(skill.name_en.toUpperCase())}
                      setCurrentStatsSelected={setCurrentStatsSelected}
                      listTypeBoots={bootsTypeListDetail || []}
                      horse_id={horse_id || ''}
                      onConfirmFinal={useItemDopingSucess}
                      onCloseModalSelectItem={toggleIsSelectItemModal}
                      currentAmountStats={handleCurrentAmountStats(skill.name_en.toUpperCase())}
                      currentPosition={handleGetPosition(skill.name_en.toUpperCase())}
                      listGetPosition={handleListGetPosition(skill.name_en.toUpperCase())}
                      getPosition={getPosition}
                      currentStatsSelected={currentStatsSelected}
                      idItemsSelected={handleGetIdItems(skill.name_en.toUpperCase())}
                      idItemsUseSelected={handleGetIdItemsUse(skill.name_en.toUpperCase())}
                      onRemoveSuccess={fetchHorse}
                      isChangeItem={isChangeItem}
                      toggleIsChangeItem={toggleIsChangeItem}
                      isLoading={isLoading}
                      horse={horse}
                      isMyHorse={false}
                      isMyLending={checkInLending()}
                    />
                  </div>
                ))}
              </div>
                <div className={`plus-item ${isShowSettingItemDoping ? 'available' : 'only-view'}`}>
                  <div className='ability-container doping-plus row gy-4'>
                    <div className='col-12 col-xxl-6 col-sm-12'>
                      <div className="d-flex justify-content-between align-items-center">
                        <img src={UNION} alt='' className='frame-union' />
                        <div className="name">ITEM PLUS</div>
                        {handleCurrentAmountStats('PLUS') == 0 ? (
                          <button
                            className='push-doping new'
                            onClick={() => showModalSelectItem('PLUS')}
                            >
                            <img src={ICON_DOPING} alt='' className='' />
                          </button>
                        ) : (
                          <button
                          className={`push-doping use ${(handleCurrentOwnerDoping('PLUS') === horse?.user?.id) ? '' : 'disabled' }`}
                            onClick={() => showModalRemoveItem(String(handleGetIdItems('PLUS')), 'PLUS')}
                            >
                              <img className='shadown' src={SHADOW_DOPING} alt="" />
                              <img src={handleCurrentImgDoping('PLUS')} alt='' className='d-block' />
                            {handleCurrentAmountStats('PLUS') !== 0 && (
                              <span> {handleCurrentAmountStats('PLUS')}</span>
                            )}
                          </button>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {isSelectItemModal &&
            <SelectItemModal
              onConfirm={useItemDopingSucess}
              onCloseButtonClick={setToggleIsSelectItemModal}
              listTypeBoots={bootsTypeListDetail || []}
              horse_id={horse_id || ''}
              position={currentPosition}
              currentStatsSelected={currentStatsSelected}
              isChangeItem={isChangeItem}
              oldItems={itemDetail}
              isLoading={isLoading}
            />
          }
          {isModalRemoveItemOpen &&
            <RemoveItemModal
              isLoading={isLoading}
              dataPreview={itemDetail}
              onRemove={handleRemoveItem}
              onChange={handleChangeItem}
              onCloseButtonClick={handleCloseModal}
            />}

          {openHorseBloodlineModal && (
            <ModalHorseBloodline
              title={bloodlineTitle}
              content={bloodlineContent}
              onClose={_handleClosePopup}
            />
          )}
        </div>
      )}
    </HorseBodyInfoStyled>
  )
}

export default HorseBodyInfoLending
