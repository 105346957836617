import { ARROW_BOTTOM } from 'assets/images'
import ToolTipStyled from './styled'

interface ToolTipProps {
  name: string
  className?: string
  posTooltip?: boolean
  bt?: number
  mgTop?: number
}

export default function ToolTipInfo({ name, posTooltip, bt = 0, mgTop = 4 }: ToolTipProps) {
  return (
    <ToolTipStyled posTooltip={posTooltip} bt={bt} mgTop={mgTop} >
      <div className='tooltips'>
        <div className='tooltip__contain'>
          <div className='content-name'>
            { name }
            <div className='contain_arrow'>
              <img src={ARROW_BOTTOM} alt='' className='arrow' />
            </div>
          </div>
        </div>
      </div>
    </ToolTipStyled>
  )
}
