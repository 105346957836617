import styled from 'styled-components'

export const ItemDetailsStyled = styled.div`
  width: 100%;
  color: white;
  padding: 0px 20px 20px 20px;
  .btn-buy{
    text-align: center;
    line-height: 20px;
    &.scale-down{
      font-size: 14px;
    }
  }
  .container {
    position: relative;
    margin-top: 40px;
    padding: 0px 2px;
    .top-line {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .bottom-line {
      transform: rotate(180deg);
      background: transparent;
      position: absolute;
      bottom: -21px;
      left: 21%;
    }
    .f-left {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
    }
    .f-right {
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
    }
    .item-block {
      position: relative;
      background: transparent;
      .body-item {
        padding: 30px;
        .item {
          display: flex;
          .name {
            padding: 0px 20px;
            .item-name {
              font-size: 20px;
              font-weight: 400;
            }
            .field-name {
              font-size: 18px;
              font-weight: 400;
              line-height: 37px;
              color: #fff566;
              min-width: 70px;
            }
            .icon-rarity{
              width: 35px;
            }
          }
        }
        .item {
          overflow: hidden;
          position: relative;
          .btn-detail-container{
            display: none !important;
          }
          &.skin {
            .avt-item {
              top: 38%;
              left: 50%;
              transform: translate(-50%, -50%) scale(1.5);
            }
          }
          &.body {
            .avt-item {
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%) scale(1.75);
            }
          }
          &.leg {
            .avt-item {
              top: 45%;
              transform: translate(-50%, -50%) scale(1.5);
            }
          }
          &.head {
            .avt-item {
              transform: translate(-50%, -50%) scale(1.75);
            }
          }
        }
        .sub-tract {
          width: 210px;
        }
        .information {
          margin-top: 20px;
          border: 1px solid #47f0b1;
          padding: 20px;
          .content{
            white-space-collapse: preserve-breaks;
          }
          .information-box {
            display: flex;
            gap: 20px;
            font-size: 18px;
            margin-bottom: 10px;
            cursor: pointer;
            .active {
              position: relative;
              color: #4ef076;
              .line-text {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%);
              }
            }
          }
        }
      }
    }
  }
  .attribute__item {
    display: inline-block;
    min-width: 66px;
  }
`

export const BtnBacktSyled = styled.div`
  .btn-back {
    width: fit-content;
    cursor: pointer;
    display: flex;
    .text-btn {
      font-size: 20px;
    }
  }
`

export const ButtonSyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
`
