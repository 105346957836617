/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import MemoizedImageRaratyCommon from 'shared/MemoizedImage/RaratyCommon'
import MemoizedImageRaratyEpic from 'shared/MemoizedImage/RaratyEpic'
import MemoizedImageRaratyLegendary from 'shared/MemoizedImage/RaratyLegendary'
import MemoizedImageRaratyMythic from 'shared/MemoizedImage/RaratyMythic'
import MemoizedImageRaratyRare from 'shared/MemoizedImage/RaratyRare'
import Head from '../Head'
import ChangeButton from '../ButtonAction/change'
import RemoveButton from '../ButtonAction/remove'
import UseButton from '../ButtonAction/use'
import horseApi from 'apis/horseApi'
import { convertTypeItems, getAttribute, handleAsyncRequest } from 'utils/helper'
import Loading from 'shared/Loading'
import { ShopTypeRariTy, weapons } from 'models'
import ItemDetailContainer from './styled'

interface itemProps {
  open: boolean
  onCancel: () => void
  itemToken: number
  isUsedItem?: boolean
  onSuccess: () => void
  type?: string
  isChangeItem?: boolean
  className?: string
  horseId: number
}

const ItemDetail = ({
  open,
  onCancel,
  itemToken,
  isUsedItem = false,
  onSuccess,
  type,
  isChangeItem,
  className,
  horseId
}: itemProps) => {
  const [item, setItem] = useState<weapons>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (itemToken) {
      fetchSyncHorseItems()
    }
  }, [itemToken])

  const fetchSyncHorseItems = async () => {
    setLoading(true)
    const [, data]: any = await handleAsyncRequest(horseApi.getWeaponDetail(itemToken))
    setLoading(false)

    if (data) {
      setItem(data?.data)
    }
  }

  const showIconRariTyCommon = (valueRariry: string) => {
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase(): {
          return <MemoizedImageRaratyCommon />
        }
        case ShopTypeRariTy.EPIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyEpic />
        }
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase(): {
          return <MemoizedImageRaratyLegendary />
        }
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyMythic />
        }
        case ShopTypeRariTy.RARE.toLocaleUpperCase(): {
          return <MemoizedImageRaratyRare />
        }
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }  

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={false}
      width={615}
      centered
      className='modal-item'
      style={{ background: 'transparent' }}
      bodyStyle={{
        background: 'transparent',
        padding: 0
      }}
    >
      <ItemDetailContainer className={className}>
        <Head name='Item information' />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className='attribute-content'>
              <div className='avatar-box'>
                <div className='avatar'>
                  <img src={item?.img} alt='icon' />
                </div>
                <div className='info'>
                  <div className='info__title'>{ item?.name }</div>
                  <div>
                    <span className='info__type__left'>Type:</span>
                    <span>{convertTypeItems(item?.body_part) || 'No Information'}</span>
                  </div>
                  <div>
                    <span className='info__type__left'>Rarity:</span>
                    <span className='info__type__icon'>{ item?.rarity_type ? showIconRariTyCommon(item?.rarity_type) : 'No Information' }</span>
                  </div>
                </div>
              </div>
              <div className='content-box'>
                <div className='attribute-head'>
                  <p>Attributes</p>
                </div>
                <div className='attribute-info'>
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: getAttribute(item?.effect) || 'No effect' }} />
                  </p>
                </div>
                <div className='attribute-head'>
                  <p>Informations</p>
                </div>
                <div className='attribute-info'>
                  <p>{item?.description || '---'}</p>
                </div>
              </div>
            </div>
            <div className='button-row'>
              {(isUsedItem && !isChangeItem)? (
                <>
                  <ChangeButton type={type} oldToken={itemToken} horseId={horseId} item={item} onChange={onCancel} onSuccess={onSuccess} />
                  <RemoveButton itemToken={itemToken} horseId={horseId} onSuccess={onSuccess} />
                </>
              ) : (
                <UseButton itemToken={itemToken} horseId={horseId} onSuccess={onSuccess} isChangeItem={isChangeItem} />
              )}
            </div>
          </>
        )}
      </ItemDetailContainer>
    </Modal>
  )
}

export default ItemDetail
