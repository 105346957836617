/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemNFT } from 'features/ShopAP/components'
import { BtnBacktSyled, ButtonSyled, ItemDetailsStyled } from './styled'
import { useEffect, useState } from 'react'
import {
  CARET_LEFT,
  LINE_ITEM,
  FRAME_TOP,
  LINE_TEXT,
  RARITY_COMMON,
  RARITY_RARE,
  RARITY_EPIC,
  RARITY_LEGENDARY,
  RARITY_MYTHIC
} from 'assets/images'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { ItemShop, ShopTypeRariTy, resultItemNFT } from 'models'
import { convertGasPrice, convertTypeItems, getAttribute, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { useAppDispatch, useToggle } from 'hooks'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import SkeletonDetail from 'features/components/SkeletonDetail'
import IconMerahOrKuda from 'features/ShopAP/components/IconMerahOrKuda'
import { ethers } from 'ethers'
import { configs } from 'apps'
import shopApNFTABI from 'json/ShopAP.json'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { notification } from 'antd'
import InProgressBalanceModal from 'features/Balance/components/InProgress'

const contract = {
  shopAP: configs.shopAP,
  itemNFT: configs.itemNFT
}

export default function ItemSellingDetails() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [itemDetails, setItemDetails] = useState<ItemShop>()
  const { id } = useParams()
  const [isConfirmBuyItemModal, toggleConfirmBuyItemModal] = useToggle(false)

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const dispatch = useAppDispatch()

  const onBuyItemClick = () => {
    toggleConfirmBuyItemModal()
  }

  const fetchItem = async () => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(itemShopsApi.getItemShopsDetails(id || ''))
    const records = result?.data
    setItemDetails(records)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchItem()
  }, [])

  const onConfirmCancelSellNft = async () => {
    cancelSellItemShopAPI()
  }

  // call api cancelSellItemShopAPI
  const cancelSellItemShopAPI = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      itemShopsApi.postCancelSellItemShopNFT({
        currency: itemDetails?.currency,
        price: itemDetails?.price,
        token_id: itemDetails?.item?.item_token
      })
    )
    if (error) {
      transactionFaild(error?.message)
      toggleConfirmBuyItemModal(false)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      cancelSellItemNFTContract(result.data)
      checkCurrentCoinUser()
      toggleConfirmBuyItemModal(false)
    }
    setIsLoading(false)
  }
  // end

  const cancelSellItemNFTContract = async (value: resultItemNFT) => {
    if (!value) return
    try {
      const addHorseContract = new ethers.Contract(contract.shopAP, shopApNFTABI.contract.abi, signer)
      const orderId = value?.order_id
      const gasPrice = convertGasPrice(value.gas_price)
      try {
        const tx = await addHorseContract.cancelSell(orderId, contract.itemNFT, { gasPrice: gasPrice })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceContract(value.nonce)
        }
      } catch (err: any) {
        console.log({ err })
        transactionFaild(err?.error?.data?.message)
      }
    } catch (err: any) {
      console.log({ err })
      transactionFaild(err?.error?.data?.message)
    }
  }

  const checkNonceContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(itemShopsApi.postCheckNonceItemShopNFT({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionSuccess()
        navigate(-1)
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
        toggleConfirmBuyItemModal(false)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          toggleConfirmBuyItemModal(false)
          transactionFaild(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
        }
      }
    }, timeCheckNonce)
  }

  const checkCurrentCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser.data))
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    notification.success({
      message: 'SUCCESS',
      description: `Cancel item successfully!`,
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  const transactionFaild = (value: any) => {
    toggleOpenInProgressBalanceModal(false)
    notification.error({
      message: 'ERROR',
      description: value ?? 'Sorry! Your transaction has failed. Please go back and try again.',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-error',
      icon: <></>
    })
  }

  const showIconRariTy = () => {
    const valueRariry = itemDetails?.item?.rarity_type || itemDetails?.boost?.rarity_type
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_COMMON} alt='' />
        case ShopTypeRariTy.EPIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_EPIC} alt='' />
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_LEGENDARY} alt='' />
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_MYTHIC} alt='' />
        case ShopTypeRariTy.RARE.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_RARE} alt='' />
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }

  return (
    <ItemDetailsStyled>
      <BtnBacktSyled>
        <div className='btn-back' onClick={() => navigate(-1)}>
          <img src={CARET_LEFT} alt='' />
          <span className='text-btn'>BACK</span>
        </div>
      </BtnBacktSyled>
      <>
        {isLoading ? (
          <SkeletonDetail rows={10} />
        ) : (
          <div className='container'>
            <img src={FRAME_TOP} alt='' className='top-line' />
            <img src={FRAME_TOP} alt='' className='bottom-line' />
            <div className='item-block'>
              <div className='body-item'>
                <div className='item'>
                  <div className='avatar'>
                    <ItemNFT
                      isDetail
                      avt={itemDetails?.item?.img || itemDetails?.boost?.img}
                      type={itemDetails?.item?.body_part || itemDetails?.boost?.boost_type}
                    />
                  </div>
                  <div className='name'>
                    <div className='item-name'>{itemDetails?.item?.name || itemDetails?.boost?.name}</div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Id </span> {itemDetails?.item?.item_token || itemDetails?.boost?.id}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Owner </span> {itemDetails?.owner?.name}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Type </span>{' '}
                      {convertTypeItems(itemDetails?.item?.body_part || itemDetails?.boost?.boost_type)}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Rarity </span> {showIconRariTy()}
                    </div>
                  </div>
                </div>
                <div className='information'>
                  <div className='information-box'>
                    <div
                      className='attribute active'
                    >
                      Attribute
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content'>
                      <p>
                        <span dangerouslySetInnerHTML={{ __html: getAttribute(itemDetails?.item?.effect) || itemDetails?.boost?.attribute || 'No effect' }} />
                      </p>
                  </div>
                  <div className='information-box'>
                    <div
                      className='description active'
                    >
                      Description
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content'>
                    {
                      itemDetails?.item?.description || itemDetails?.item_info || '---'
                    }
                  </div>
                </div>
              </div>
              <img className='f-left' src={LINE_ITEM} alt='' />
              <img className='f-right' src={LINE_ITEM} alt='' />
            </div>
          </div>
        )}
        <ButtonSyled>
          <Button
            buttonName={
              <div className={`btn-buy ${itemDetails?.price && (itemDetails?.price.toString().length > 10) ? 'scale-down' : ''}`}>
                <IconMerahOrKuda type={itemDetails?.currency} price={itemDetails?.price} /> <span> <br /> Cancel</span>
              </div>
            }
            onClickButton={onBuyItemClick}
            width={150}
            isLoading={isLoading}
            type="btnCancel"
          />
        </ButtonSyled>
      </>

      {isConfirmBuyItemModal && (
        <ConfirmOkModal
          toggleIsModalOpen={toggleConfirmBuyItemModal}
          onCloseButtonClick={toggleConfirmBuyItemModal}
          onConfirm={onConfirmCancelSellNft}
          message={`You want to cancel the sale of ${
            itemDetails?.item?.name || itemDetails?.boost?.name
          } on the shop. \nAre you sure? `}
          isLoading={isLoading}
          heading='Confirm'
          isContentCenter
        />
      )}
      {openInProgressBalanceModal && <InProgressBalanceModal title={`CANCEL ITEM IN PROGRESS`} />}
    </ItemDetailsStyled>
  )
}
