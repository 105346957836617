import { BG_TOOLTIP } from 'assets/images'
import styled from 'styled-components'

interface Props {
  posTooltip?: boolean;
  bt?: number
  mgTop?: number
}

const ToolTipStyled = styled.div<Props>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;

  .tooltips {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    z-index: 99;

    &:hover {
      .tooltip__contain {
        display: block;
        z-index: 99;

        .content-name {
          display: block;
          z-index: 99;
        }

        .arrow {
          display: block;
          z-index: 99;
        }
      }
      
    }

    .tooltip__contain {
      display: none;
      position: absolute;
      top: ${p => p.posTooltip ? "50px" : "auto"};
      bottom: ${p => p.posTooltip ? "auto" : "50px"};
      left: 50%;
      transform: translate(-40%, 0);
      
      background: url(${BG_TOOLTIP});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 99;

      .content-name {
        display: none;
        position: relative;
        width: 320px;
        height: auto;
        line-height: 24px;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 10px 20px;
        text-align: justify;
        word-break: break-word;
        z-index: 99;
        /* -webkit-clip-path: polygon(20px 0, 100% 0, 100% 20%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20% 100%, 0 100%, 0 20px);
        clip-path: polygon(20px 0, 100% 0, 100% 20%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20% 100%, 0 100%, 0 20px); */
      }

      .contain_arrow {
        display: block;
        position: absolute;
        top: ${p => p.posTooltip ? `${p.mgTop}px` : "auto"};
        bottom: ${p => p.posTooltip ? "auto" : `${p.bt}px`};
        transform: ${p => p.posTooltip ? "rotate(180deg)" : "rotate(90deg)"}; ;
        left: 75px;

        .arrow {
          width: 14px;
          height: 14px;
        }
      }
    }

    /* .tooltip__contain::after {
      content: '';
      position: absolute;
      bottom: ${p => p.posTooltip ? `${p.bt}%` : "-8px"};
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
      width: 10px;
      height: 10px;
      background: #4ef076;
      border: 2px solid #4ef076;
      border-top-color: ${p => p.posTooltip ? "#4ef076" : '#4ef076'};
      border-left-color: ${p => p.posTooltip ? "#4ef076" : '#4ef076'};
      border-right-color: ${p => p.posTooltip ? '#4ef076' : "#4ef076"};
      border-bottom-color: ${p => p.posTooltip ? '#4ef076' : "#4ef076"};
      z-index: 110;
    } */

    /* .tooltip__contain {
      -webkit-clip-path: polygon(20px 0, 100% 0, 100% 20%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20% 100%, 0 100%, 0 20px);
      clip-path: polygon(20px 0, 100% 0, 100% 20%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20% 100%, 0 100%, 0 20px);
    } */
  }
  
`

export default ToolTipStyled
