import { HORSE_RANK_AVATAR_FRAME } from 'assets/images'
import styled from 'styled-components'

interface HorseRankBoxStyledProps {
  currentRank: number
  isHover: boolean
}

const DISTANCE_FROM_TOP_TO_RANK_BOX_EVERY_RANK = 60

const HorseRankBoxStyled = styled.div.attrs<HorseRankBoxStyledProps>(({ currentRank }) => ({
  style: {
    top: currentRank * DISTANCE_FROM_TOP_TO_RANK_BOX_EVERY_RANK
  }
}))<HorseRankBoxStyledProps>`
  transition-duration: 0.5s;
  transform: ${({ isHover }) => (isHover ? 'scale(1.15)' : 'scale(1)')};
  background-color: ${({ isHover }) => (isHover ? '#ffff' : 'none')};
  &:hover {
    transform: scale(1.15);
  }
  &.active {
    &:after {
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: #2c7033;
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      -webkit-clip-path: polygon(17% 0, 84% 0, 100% 12%, 100% 96%, 80% 96%, 79% 100%, 0 100%, 0 4%, 14% 4%);
      clip-path: polygon(11% 0, 94% 0, 100% 16%, 100% 96%, 90% 96%, 90% 100%, 0 100%, 0 4%, 10% 4%);
      z-index: 0;
    }
  }

  .horse-rank-box {
    padding: 12px 9px;
    height: 50px;
    width: 257px;
    gap: 12px;
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(11% 0, 94% 0, 100% 16%, 100% 96%, 90% 96%, 90% 100%, 0 100%, 0 4%, 10% 4%);
    padding-right: 30px;
    &-active {
      z-index: 1;
      position: relative;
      background: #191d2cf2;
    }
    img.icon-highlight{
      transform: scale(0.1);
      position: absolute;
      right: -42%;
    }

    .avatar-container {
      width: 38px;
      aspect-ratio: 1;
      background-image: url(${HORSE_RANK_AVATAR_FRAME});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      .avatar {
        width: 30px;
        aspect-ratio: 1;
      }
    }

    .gate {
      color: #3fbc71;
      display: flex;
      align-items: center;
      .gate-rank {
        font-size: 24px;
      }
    }

    .name-container {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .horse-name {
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
      }
    }
    .rank-bg {
      width: 0px;
      padding-bottom: 60px;
      left: 125px;
    }
    .gate-container {
      width: 20px;
      aspect-ratio: 1;
      background-color: ${({ theme }) => theme.color.positionTrack};
      clip-path: polygon(0 0, 100% 0, 100% 100%, 6px 100%, 0% calc(100% - 6px));
      &.no-1{
        background: #ffffff;
        color: #000000;
      }
      &.no-2{
        background: #000000;
        color: #ffffff;
      }
      &.no-3{
        background: red;
        color: #ffffff;
      }
      &.no-4{
        background: #3366FF;
        color: #ffffff;
      }
      &.no-5{
        background: yellow;
        color: #000000;
      }
      &.no-6{
        background: yellow;
        color: #000000;
      }
      &.no-7{
        background: green;
        color: #ffffff;
      }
      &.no-8{
        background: green;
        color: #ffffff;
      }
      &.no-9{
        background: #FF8C00;
        color: #ffffff;
      }
      &.no-10{
        background: #FF8C00;
        color: #ffffff;
      }
      &.no-11{
        background: #FF33CC;
        color: #ffffff;
      }
      &.no-12{
        background: #FF33CC;
        color: #ffffff;
      }
      .gate-number {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .top-rank {
    background: ${({ currentRank }) => {
      switch (currentRank) {
        case 0:
          return '#3A342F'
        case 1:
          return '#2B2A2A'
        case 2:
          return '#232128'
        default:
          return ''
      }
    }};
  }

  .region {
    width: 20px;
    height: 20px;

    img {
      object-fit: contain;
    }

    img[src=""], img:not([src]){
      opacity: 0;
    }

    img[src="*"]{
      opacity: 1;
    }
  }
`

export default HorseRankBoxStyled
